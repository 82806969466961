/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Cryptologic
                    <br />
                    Inc.
                  </>
                }
                description="bring the power of blockchain technology to the un-banked masses."
              />
              <RotatingCardBack
                image={bgBack}
                title="Discover More"
                description="empowering the world via crypto."
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Our mission statement"
                  description="At Cryptologic, we are committed to revolutionizing the exchange of digital assets by making cryptocurrency trading accessible, secure, and efficient for traders worldwide. Our mission is to unlock the potential of decentralized finance for everyone, providing a platform where our users can invest, trade, and manage assets with confidence and ease."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="Our vision"
                  description="Cryptologic is dedicated to shaping the future of finance through decentralization, offering an accessible and intuitive platform that addresses the complexities of cryptocurrency. Our commitment lies in empowering a global community with the tools and knowledge to engage confidently in the crypto economy."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="Future Outlook"
                  description="Cryptologic is poised for expansion, with plans to introduce new products and enter new markets."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
